import SectionPrivacyPolice from "../components/InformationComponents/PrivacyPolice/SectionPrivacyPolice/SectionPrivacyPolicy";
import SectionNavbarInfo from "../components/SectionNavbarInfo/SectionNavbarInfo";

export default function PrivacyPolicy() {
    return (
        <main className="page">
            <SectionNavbarInfo />
            <SectionPrivacyPolice/>
        </main>
    )
}