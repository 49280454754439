import React, { useEffect, useRef, useState } from 'react';

import styles from './SectionAccount.module.scss';
import Icon01 from './assets/01.png';
import Icon02 from './assets/02.png';
import Icon03 from './assets/03.png';
import Icon04 from './assets/04.png';
import Icon05 from './assets/05.png';
import Icon06 from './assets/06.png';
import Icon07 from './assets/07.png';
import Icon08 from './assets/08.png';
interface User {
    id: number;
    first_name: string;
    last_name?: string;
    username?: string;
}
export default function SectionAccounts() {
    const [userInfo, setUserInfo] = useState<User | null | any>(null);
    const [initData, setInitData] = useState<string>('');

    useEffect(() => {
        const initializeTelegramWebApp = () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.ready();

                const initData = window.Telegram.WebApp.initData;
                const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;

                setInitData(initData);
                setUserInfo(initDataUnsafe.user);
            }
        };

        initializeTelegramWebApp();
    }, []);
    // const carouselRef = useRef(null);
    // const [isPaused, setIsPaused] = useState(false);
    // const intervalRef: any = useRef(null);

    // useEffect(() => {
    //     const carousel: any = carouselRef.current;
    //     const content = carousel.innerHTML;
    //     carousel.innerHTML += content;

    //     let position = 0;
    //     const speed = 1; // Скорость прокрутки

    //     const moveCarousel = () => {
    //         position -= speed;
    //         if (position <= -carousel.offsetWidth / 2) {
    //             position = 0;
    //         }
    //         carousel.style.left = position + 'px';
    //     };

    //     intervalRef.current = setInterval(moveCarousel, 16); // Около 60 FPS

    //     return () => clearInterval(intervalRef.current); // Очищаем интервал при размонтировании
    // }, []);

    // const handleMouseOver = () => {
    //     setIsPaused(true);
    //     clearInterval(intervalRef.current); // Останавливаем прокрутку немедленно
    // };

    // const handleMouseOut = () => {
    //     setIsPaused(false);
    //     intervalRef.current = setInterval(() => {
    //         const carousel: any = carouselRef.current;
    //         let position = parseInt(carousel.style.left, 10) || 0;
    //         const speed = 1; // Скорость прокрутки
    //         position -= speed;
    //         if (position <= -carousel.offsetWidth / 2) {
    //             position = 0;
    //         }
    //         carousel.style.left = position + 'px';

    //     }, 16); // Возобновляем прокрутку
    // };
    return (
        <section className={styles["page__accounts"]} >
            <div className="accounts__container">
                <div className={styles["accounts__content"]} >
                    <div className={styles["accounts__items"]}>
                        <div className={styles["accounts__item"]}>
                            <img src={Icon02} alt="" />
                        </div>
                        <div className={styles["accounts__item"]}>
                            <img src={Icon03} alt="" />
                        </div>
                        <div className={styles["accounts__item"]}>
                            <img src={Icon04} alt="" />
                        </div>
                        <div className={styles["accounts__item"]}>
                            <img src={Icon05} alt="" />
                        </div>
                        <div className={styles["accounts__item"]}>
                            <img src={Icon06} alt="" />
                        </div>
                        <div className={styles["accounts__item"]}>
                            <img src={Icon07} alt="" />
                        </div>
                        <div className={styles["accounts__item"]}>
                            <img src={Icon08} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {
                <>
                    <p>
                        {initData}
                    </p>
                </>
            }
        </section>
    )
}