import classnames from 'classnames';
import styles from './SectionProfileHistory.module.scss';
import Avatar from './assets/01.jpeg';
import Storie from './assets/02.png';
import Pause from './assets/pause.png';
import Burger from './assets/burger.png';
export default function SectionProfileHistory() {

    return (
        <section className="page__history">
            <div className="history__container">
                <div className={styles["history"]}>
                    <div className={styles["history__header"]}>
                        <div className={styles["history__header-title"]}>Історія слідкування</div>
                        <div className={styles["history__header-btns"]}>
                            <button className={styles["history__header-btns__btn"]}>
                                <img alt="Pause Icon" src={Pause} className={styles["history__header-btns__btn-ic"]} />
                            </button>
                            <div className={styles["history__header-btns__btn-div"]}>
                            </div>
                            <button className={styles["history__header-btns__btn"]}>
                                <img alt="Burger Icon" src={Burger} className={styles["history__header-btns__btn-ic"]} />
                            </button>
                        </div>
                    </div>
                    <div className={styles["history__istories"]}>
                        <div className={classnames(styles["history__istories__item"], styles.active)}>
                            <div className={styles["history__istories__item-header"]}>
                                <img src={Avatar} className={styles["history__istories__item-header_avatar"]} alt="" />
                                <div>
                                    <div className={styles["history__istories__item-header__name"]}>solar_sea</div>
                                    <div className={styles["history__istories__item-header__date"]}>сьогодні о 20:53</div>
                                </div>
                            </div>
                            <div className={classnames(styles["history__istories__item_content-wrap"], styles.active)}>
                                <img src={Storie} className={styles["history__istories__item_content"]} alt="" />
                            </div>
                        </div>
                        <div className={styles["history__istories__item"]}>
                            <div className={styles["history__istories__item-header"]}>
                                <img src={Avatar} className={styles["history__istories__item-header_avatar"]} alt="" />
                                <div>
                                    <div className={styles["history__istories__item-header__name"]}>solar_sea</div>
                                    <div className={styles["history__istories__item-header__date"]}>сьогодні о 20:53</div>
                                </div>
                            </div>
                            <div className={styles["history__istories__item_content-wrap"]}>
                                <img src={Storie} className={styles["history__istories__item_content"]} alt="" />
                            </div>
                        </div>
                        <div className={styles["history__istories__item"]}>
                            <div className={styles["history__istories__item-header"]}>
                                <img src={Avatar} className={styles["history__istories__item-header_avatar"]} alt="" />
                                <div>
                                    <div className={styles["history__istories__item-header__name"]}>solar_sea</div>
                                    <div className={styles["history__istories__item-header__date"]}>сьогодні о 20:53</div>
                                </div>
                            </div>
                            <div className={styles["history__istories__item_content-wrap"]}>
                                <img src={Storie} className={styles["history__istories__item_content"]} alt="" />
                            </div>
                        </div>
                        <div className={styles["history__istories__item"]}>
                            <div className={styles["history__istories__item-text"]}>
                                <div className={styles["history__istories__item-text-content"]}>
                                    <p>Зберігається лише 7 днів</p>
                                    <p>Для <span>безлімітного сховища</span>, налаштуйте надсилання сторісів в Telegram</p>
                                    <a href="https://t.me/GramsterTestBot" className={styles["history__istories__item-text-btn"]} target='_blank'>До бота</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}