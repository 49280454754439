import styles from '../../SectionInfoRule.module.scss';

export default function SectionInfoRule() {
    return (
        <section className="page-info">
            <div className="info-rule__container">
                <div className={styles["info-rule__content"]}>
                    <div className="info-rule-top">
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>Правила користування сервісом</div>
                            <div className={styles["info-rule__content-title"]}>1. Вступ</div>
                            <div className={styles["info-rule__content-description"]}>
                                Ми надаємо можливість моніторингу історій в Instagram. Скористайтеся нашими безкоштовними та платними тарифами для оптимального досвіду. Будь ласка, ознайомтеся з нашими правилами користування, щоб повністю зрозуміти наші умови та можливості.
                            </div>
                        </div>
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>2. Безкоштовний профіль</div>
                            <ul className={styles["info-rule__content-list"]}>
                                <li className={styles["info-rule__content-item"]}>
                                    Опис: Користувачі можуть отримати доступ до безкоштовного профілю для моніторингу історій в Instagram.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Умови доступу: Для отримання безкоштовного профілю необхідно підписатися на наш Telegram-канал
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Функціонал: Моніторинг історій в Instagram відбувається кожні 60 хвилин.
                                </li>
                            </ul>
                        </div>
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>3. Платні тарифи</div>
                            <div className={styles["info-rule__content-description"]}>
                                Ми пропонуємо два платних тарифи, що забезпечують покращений досвід моніторингу історій в Instagram.
                            </div>
                            <ul className={styles["info-rule__content-list"]}>
                                <div className={styles["info-rule__content-list-title"]}>
                                    Тариф на 30 днів
                                </div>
                                <li className={styles["info-rule__content-item"]}>
                                    Термін дії: 30 днів.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Кількість профілів: Можливість одночасного моніторингу до 3 профілів.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Частота моніторингу: Історії в Instagram збираються кожні 60 секунд.
                                </li>
                            </ul>
                            <ul className={styles["info-rule__content-list"]}>
                                <div className={styles["info-rule__content-list-title"]}>
                                    Тариф на 60 днів
                                </div>
                                <li className={styles["info-rule__content-item"]}>
                                    Термін дії: 60 днів.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Кількість профілів: Можливість одночасного моніторингу до 6 профілів.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Частота моніторингу: Історії в Instagram збираються кожні 60 секунд.
                                </li>
                            </ul>
                        </div>
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>4. Загальні положення</div>
                            <ul className={styles["info-rule__content-list"]}>
                                <li className={styles["info-rule__content-item"]}>
                                    Реєстрація: Для користування сервісом необхідно створити обліковий запис.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Оплата: Оплата за платні тарифи здійснюється через доступні на сайті способи. Підписка автоматично поновлюється, якщо не скасована до кінця терміну дії.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Скасування підписки: Ви можете скасувати підписку в будь-який час через налаштування облікового запису на нашому веб-сайті.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Політика повернення грошей: Повернення коштів за вже здійснені періодичні платежі (місячні або річні) не передбачено. Ви можете ознайомитися з детальною політикою повернення грошей на нашому сайті.
                                </li>
                            </ul>
                        </div>
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>5. Відповідальність</div>
                            <ul className={styles["info-rule__content-list"]}>
                                <li className={styles["info-rule__content-item"]}>
                                    Безпека даних: Ми докладаємо максимальних зусиль для захисту ваших даних і забезпечення конфіденційності.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Обмеження відповідальності: Ми не несемо відповідальності за можливі перебої в роботі сервісу або втрату даних через технічні причини або дії третіх осіб.
                                </li>
                            </ul>
                        </div>
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>6. Зміни до правил</div>
                            <div className={styles["info-rule__content-description"]}>
                                Ми залишаємо за собою право змінювати ці правила в будь-який час. Про зміни буде повідомлено на нашому веб-сайті, телеграм боті або через електронну пошту.
                            </div>
                        </div>
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>7. Контактна інформація</div>
                            <div className={styles["info-rule__content-description"]}>
                                Якщо у вас є запитання або занепокоєння щодо правил користування сервісом, будь ласка, зв'яжіться з нами за допомогою наступних контактних даних:
                            </div>
                            <ul className={styles["info-rule__content-list"]}>
                                <li className={styles["info-rule__content-item"]}>
                                    Електронна адреса: senya@gramster.org
                                </li>
                            </ul>
                            <div className={styles["info-rule__content-description"]}>
                                Ці правила користування сервісом набирають чинності з 22.06.2024.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}