import SectionInfoRule from "../components/InformationComponents/Rules/SectionInfoRule/SectionInfoRule";
import SectionNavbarInfo from "../components/SectionNavbarInfo/SectionNavbarInfo";

export default function Rules() {
    return (
        <main className="page">
            <SectionNavbarInfo />
            <SectionInfoRule/>
        </main>
    )
}