import SectionProfileAcc from "../components/SectionProfile/SectionProfileAcc/SectionProfileAcc";
import SectionProfileHistory from "../components/SectionProfile/SectionProfileHistory/SectionProfileHistory";
import SectionProfileTelegram from "../components/SectionProfile/SectionProfileTelegram/SectionProfileTelegram";

export default function PrivacyPolicy() {
    return (
        <main className="page">
            <SectionProfileAcc/>
            <SectionProfileTelegram/>
            <SectionProfileHistory/>
        </main>
    )
}