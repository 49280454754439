import React, { useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import './styles/globals.css';
import Preloader from './components/Preloader/Preloader';
import Main from './Main';

function App() {
  const [loading, setLoading] = useState(true);

  return (
    <>
        <BrowserRouter>
          {/* {loading ? <Preloader /> : */}
           <Main setLoading={setLoading} />
            {/* } */}
        </BrowserRouter>
    </>
  );
}

export default App;
