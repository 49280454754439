import styles from './SectionTariffs.module.scss';
import classnames from 'classnames';
export default function SectionTariffs() {
    return (
        <section className={styles["page__tariff"]}>
            <div className="tariff__container">
                <div className={styles["tariff__content"]}>
                    <div className={styles["tariff__title"]}>
                        Тарифи
                    </div>
                    <div className={styles["tariff__wrap"]}>
                        <div className={classnames(styles["tariff__item"], styles.one)}>
                            <div className={styles["tariff__period"]}>∞</div>
                            <div className={styles["tariff__profile"]}>1 профіль</div>
                            <div className={styles["tariff__price"]}>Безкоштовно</div>
                            <button className={classnames(styles["tariff__button"], styles.fill)}>Активований</button>
                        </div>
                        <div className={classnames(styles["tariff__item"], styles.two)}>
                            <div className={styles["tariff__period"]}>30 днів</div>
                            <div className={styles["tariff__profile"]}>3 профілі</div>
                            <div className={styles["tariff__price"]}>за 309 грн</div>
                            <button className={styles["tariff__button"]}>Купити</button>
                        </div>
                        <div className={classnames(styles["tariff__item"], styles.three)}>
                            <div className={styles["tariff__period"]}>90 днів</div>
                            <div className={styles["tariff__profile"]}>9 профілів</div>
                            <div className={styles["tariff__price"]}>за 809 грн</div>
                            <button className={styles["tariff__button"]}>Купити</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}