import styles from '../../SectionInfoRule.module.scss';

export default function SectionPrivacyPolice() {
    return (
        <section className="page-info">
            <div className="info-rule__container">
                <div className={styles["info-rule__content"]}>
                    <div className="info-rule-top">
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>Політика конфіденційності</div>
                            <div className={styles["info-rule__content-title"]}>1. Вступ</div>
                            <div className={styles["info-rule__content-description"]}>
                                Ця політика конфіденційності описує, як ми збираємо, використовуємо, зберігаємо та захищаємо вашу особисту інформацію при користуванні нашим веб-сайтом та послугами. Ми поважаємо вашу конфіденційність і зобов'язуємося захищати вашу особисту інформацію.
                            </div>
                        </div>
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>2. Збір інформації</div>
                            <div className={styles["info-rule__content-description"]}>
                                Ми можемо збирати наступні типи особистої інформації:
                            </div>
                            <ul className={styles["info-rule__content-list"]}>
                                <li className={styles["info-rule__content-item"]}>
                                    Інформація, яку ви надаєте нам: при реєстрації на сайті, заповненні форм або використанні наших послуг (ім'я, телеграм, номер телефону тощо).
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Автоматично зібрана інформація: інформація про ваш пристрій, IP-адресу, тип браузера, час доступу і сторінки, які ви відвідуєте.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Інформація з інших джерел: ми можемо отримувати інформацію від третіх сторін, таких як соціальні мережі, якщо ви вирішите увійти через них.
                                </li>
                            </ul>
                        </div>
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>3. Використання інформації</div>
                            <div className={styles["info-rule__content-description"]}>
                                Ми використовуємо вашу особисту інформацію для:
                            </div>
                            <ul className={styles["info-rule__content-list"]}>
                                <li className={styles["info-rule__content-item"]}>
                                    Надання та покращення наших послуг.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Спілкування з вами щодо вашого облікового запису або замовлень.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Надсилання маркетингових матеріалів, якщо ви дали на це згоду.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Аналізу та дослідження для покращення наших продуктів та послуг.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Захисту прав та власності нашої компанії та користувачів.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles["info-rule__content-wrap-text"]}>
                        <div className={styles["info-rule__content-title"]}>4. Зберігання інформації</div>
                        <div className={styles["info-rule__content-description"]}>
                            Ми зберігаємо вашу особисту інформацію лише протягом часу, необхідного для виконання цілей, зазначених у цій Політиці конфіденційності, або у відповідності з вимогами закону.
                        </div>
                    </div>
                    <div className={styles["info-rule__content-wrap-text"]}>
                        <div className={styles["info-rule__content-title"]}>5. Захист інформації</div>
                        <div className={styles["info-rule__content-description"]}>
                            Ми застосовуємо належні технічні та організаційні заходи для захисту вашої особистої інформації від несанкціонованого доступу, використання або розголошення.
                        </div>
                    </div>
                    <div className={styles["info-rule__content-wrap-text"]}>
                        <div className={styles["info-rule__content-title"]}>6. Передача інформації третім особам</div>
                        <div className={styles["info-rule__content-description"]}>
                            Ми не передаємо вашу особисту інформацію третім особам, за винятком випадків:
                        </div>
                        <ul className={styles["info-rule__content-list"]}>
                            <li className={styles["info-rule__content-item"]}>
                                Коли це необхідно для надання вам наших послуг.
                            </li>
                            <li className={styles["info-rule__content-item"]}>
                                Коли ми маємо вашу явну згоду.
                            </li>
                            <li className={styles["info-rule__content-item"]}>
                                Коли це вимагається законом.
                            </li>
                        </ul>
                    </div>
                    <div className={styles["info-rule__content-wrap-text"]}>
                        <div className={styles["info-rule__content-title"]}>7. Ваші права</div>
                        <div className={styles["info-rule__content-description"]}>
                            Ви маєте право:
                        </div>
                        <ul className={styles["info-rule__content-list"]}>
                            <li className={styles["info-rule__content-item"]}>
                                Доступу до своєї особистої інформації.
                            </li>
                            <li className={styles["info-rule__content-item"]}>
                                Виправлення неточної або неповної інформації.
                            </li>
                            <li className={styles["info-rule__content-item"]}>
                                Видалення своєї особистої інформації.
                            </li>
                            <li className={styles["info-rule__content-item"]}>
                                Обмеження або заперечення обробки вашої інформації.
                            </li>
                            <li className={styles["info-rule__content-item"]}>
                                Перенесення даних до іншого постачальника послуг.
                            </li>
                        </ul>
                    </div>
                    <div className={styles["info-rule__content-wrap-text"]}>
                        <div className={styles["info-rule__content-title"]}>8. Зміни до політики конфіденційності</div>
                        <div className={styles["info-rule__content-description"]}>
                            Ми можемо періодично оновлювати цю політику конфіденційності. Ми повідомимо вас про будь-які зміни, опублікувавши нову політику конфіденційності на нашому веб-сайті.
                        </div>
                    </div>
                    <div className={styles["info-rule__content-wrap-text"]}>
                        <div className={styles["info-rule__content-title"]}>9. Контактна інформація</div>
                        <div className={styles["info-rule__content-description"]}>
                            Якщо у вас є запитання або занепокоєння щодо цієї політики конфіденційності, будь ласка, зв'яжіться з нами за допомогою наступних контактних даних:
                        </div>
                        <ul className={styles["info-rule__content-list"]}>
                            <li className={styles["info-rule__content-item"]}>
                                Електронна адреса: senya@gramster.org
                            </li>
                        </ul>
                        <div className={styles["info-rule__content-description"]}>
                            Ця політика конфіденційності набирає чинності з 21.06.2024.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}