import styles from './SectionProfileTelegram.module.scss'

export default function SectionProfileTelegram() {
    return (
        <section className={styles["page__telegram"]}>
            <div className="profle-telegram__container">
                <a href='https://t.me/GramsterTestBot' target='_blank' className={styles["profile-telegram__content"]}>
                    {/* <p className={styles["profile-telegram__title"]}> */}
                      Сповіщення в Telegram
                    {/* </p> */}
                </a>
            </div>
        </section>
    )
}