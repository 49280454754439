import Logo from './assets/logo.svg';
import PersonIcon from './assets/person.svg';
import styles from './Header.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
interface User {
    id: number;
    first_name: string;
    last_name?: string;
    username?: string;
}
export default function Header() {
    const [isDropLng, setIsDropLng] = useState(false);
    const [isDropProfile, setIsDropProfile] = useState(false);
    const [userInfo, setUserInfo] = useState<User | null>(null);
    const [initData, setInitData] = useState<string>('');
    const location = useLocation();
    const dropRef: any = useRef(null);
    const navigate = useNavigate();

    const handleClickOutside = (event: any) => {
        if (dropRef.current && !dropRef.current.contains(event.target)) {
            setIsDropProfile(false);
        }
    };

    useEffect(() => {
        const initializeTelegramWebApp = () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.ready();

                const initData = window.Telegram.WebApp.initData;
                const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;

                setInitData(initData);
                setUserInfo(initDataUnsafe.user);
                console.log(initData, 'initData')
                console.log(initDataUnsafe, 'initDataUnsafe')
            }
        };

        initializeTelegramWebApp();
        if (isDropProfile) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropProfile]);
    return (
        <header className={classnames(styles.header, (location.pathname === '/profile' || location.pathname === '/tariffs') ? styles.gradient : '')} ref={dropRef}>
            <div className={styles["header__container"]}>
                <div className={styles["header__left"]}>
                    <Link to='/' className={styles["header-left__logotype"]}>
                        <img src={Logo} alt="Logotype" />
                        Gramster
                    </Link>

                    {/* <div className={styles["header-left__language"]}>
                        <div className={styles['language-drop']}>
                            <div className={styles['language-drop__icon']} onClick={() => setIsDropLng(!isDropLng)}>
                                RU
                            </div>
                            {isDropLng &&
                                <ul className={styles['language-drop__list']}>
                                    <li className={styles['language-drop__item']} onClick={() => { window.location.href = (`/en`) }}>
                                        <button className={classnames(styles['language-drop__button'], styles.active)}>
                                            Русский язык
                                        </button>
                                    </li>
                                    <li className={styles['language-drop__item']} onClick={() => { window.location.href = (`/uk`) }}>
                                        <button className={classnames(styles['language-drop__button'])}>
                                            Українська мова
                                        </button>
                                    </li>
                                    <li className={styles['language-drop__item']} onClick={() => { window.location.href = (`/ru`) }}>
                                        <button className={classnames(styles['language-drop__button'])}>
                                            English language
                                        </button>
                                    </li>
                                </ul>
                            }
                        </div>
                    </div> */}
                </div>
                <div className={styles["header-right"]}>
                    <div className={styles["profile-drop"]}>
                        <div className={classnames(styles["profile-drop__icon"], isDropProfile ? styles.active : '')}>
                            <img src={PersonIcon} alt="Icon Person" onClick={() => setIsDropProfile(!isDropProfile)} />
                        </div>
                        {isDropProfile &&
                            <ul className={styles['profile-drop__list']}>
                                <div className={styles["profile-drop__wrap-tariff"]}>
                                    <div className={styles["profile-drop__tariff"]}>
                                        <span>1 профіль</span>
                                        Залишилось: ∞
                                    </div>
                                </div>
                                <li className={styles['profile-drop__item']}>
                                    <button className={classnames(styles['profile-drop__button'], styles.active)}>
                                        Відстеження
                                    </button>
                                </li>
                                <li className={styles['profile-drop__item']}>
                                    <button className={classnames(styles['profile-drop__button'])}>
                                        Тарифи
                                    </button>
                                </li>
                                <li className={styles['profile-drop__item']}>
                                    <button className={classnames(styles['profile-drop__button'])}>
                                        Вийти
                                    </button>
                                </li>
                            </ul>
                        }
                    </div>
                    <button className={styles["header-right__button-deposit"]} onClick={() => navigate('/tariffs')}>
                        Поповнити
                    </button>
                    {/* <button className={styles["header-right__button-deposit"]} onClick={() => navigate('/tariffs')}>
                        Регистрация
                    </button> */}
                </div>
            </div>
            
        </header>
    )
}