import styles from './SectionCards.module.scss'
import IconMC from './assets/mc.svg';

export default function SectionCards() {
    return (
        <section className={styles["page__cards"]}>
            <div className="cards__container">
                <div className={styles["cards__content"]}>
                    <div className={styles["cards__title"]}>
                        Банк картки: <span>1</span>
                    </div>
                    <div className={styles["cards__items"]}>
                        <div className={styles["cards__item-wrap"]}>
                            <div className={styles["cards__item"]}>
                                <img src={IconMC} alt="" className={styles["cards__icon"]} />
                                <div className={styles["cards__num"]}>
                                    •••• •••• •••• ••••
                                </div>
                            </div>
                        </div>
                        <div className={styles["cards__item-wrap"]}>
                            <div className={styles["cards__item"]}>
                                <img src={IconMC} alt="" className={styles["cards__icon"]} />
                                <div className={styles["cards__num"]}>
                                    5169 •••• •••• 3443
                                </div>
                            </div>
                            <button className={styles["cards__btn"]}>
                             Відв'язати
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}