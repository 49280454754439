import SectionCards from "../components/SectionTariffs/SectionCards/SectionCards";
import SectionTariff from "../components/SectionTariffs/SectionTariff/SectionTariff";

export default function Tariffs() {
    return (
        <main className="page">
            <SectionTariff/>
            <SectionCards/>
        </main>
    )
}