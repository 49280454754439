import SectionAccounts from "../components/Home/SectionAccounts/SectionAccounts";
import SectionInformation from "../components/Home/SectionInformation/SectionInformation";
import SectionTariffs from "../components/Home/SectionTariffs/SectionTariffs";

export default function Home() {
    return (
        <main className="page">
            <SectionAccounts />
            <SectionInformation />
            <SectionTariffs/>
        </main>
    )
}