import { Link } from 'react-router-dom';
import styles from './SectionNavbarInfo.module.scss';

export default function SectionNavbarInfo() {
    return (
        <section className={styles["navbar-info"]}>
            <div className="navbar-info__container">
                <ul className={styles["navbar-info__list"]}>
                    <li className={styles["navbar-info__item"]}>
                        <Link to='/privacy-policy' className={styles["navbar-info__link"]}>
                           Політика конфіденційності
                        </Link>
                    </li>
                    <li className={styles["navbar-info__item"]}>
                        <Link to="/return-policy" className={styles["navbar-info__link"]}>
                          Політика повернення
                        </Link>
                    </li>
                    <li className={styles["navbar-info__item"]}>
                        <Link to="/rules" className={styles["navbar-info__link"]}>
                          Правила користування
                        </Link>
                    </li>
                </ul>
            </div>
        </section>
    )
}