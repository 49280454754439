import { useState } from 'react';
import styles from './SectionInformation.module.scss';
import ArrowIcon from './assets/arrow.svg';
import classnames from 'classnames';
import { useNavigate } from "react-router-dom";

export default function SectionInformation() {
    const [linkInput, setLinkInput] = useState('');
    const [isNotification, setIsNotification] = useState({
        visible: false,
        error: false
    });
    const navigate = useNavigate();
    const validLink = () => {
        if (
            !(
                linkInput.includes('https://www.instagram.com') ||
                linkInput.startsWith('@')
            )
        ) {
            console.log("Invalid Instagram link or username");
            if (isNotification.visible) return;
            setIsNotification({ visible: true, error: true });
            setTimeout(function () {
                setIsNotification({
                    visible: false, error: true
                })
            }, 2000);
        } else {
            if (isNotification.visible) return;
            setIsNotification({ visible: true, error: false });
            setTimeout(function () {
                setIsNotification({
                    visible: false, error: false
                })
                navigate('/profile')
            }, 2000);
            console.log("Valid input");
        }
    };

    return (
        <section className={styles["page__information"]}>
            <div className="information__container">
                <div className={styles["information__content"]}>
                    <h1 className={styles["information__title"]}>
                        Gramster
                    </h1>
                    <h2 className={styles["information__description"]}>
                    Грамстер буде стежити анонімно за появою історій у профілях Instagram і зберігатиме їх одразу після публікації, щоб ви могли стежити за видаленими моментами своїх друзів.
                    </h2>
                    <div className={styles["information__form-container"]}>
                        <div className={styles["information__input-wrap"]}>
                            <input type="text" className={styles["information__input"]} placeholder="Посилання на профіль" onChange={(e) => setLinkInput(e.target.value)} />
                            <p className={styles['information__input-text']}>* Лише відкриті акаунти</p>
                        </div>
                        <button className={styles["information__btn"]} onClick={validLink}>
                            <img src={ArrowIcon} alt="Arrow Icon" />
                        </button>
                    </div>
                </div>
            </div>
            <div
                className={classnames(
                    styles['notification-access'],
                    {
                        [styles.show]: isNotification.visible,
                        [styles.red]: isNotification.error && isNotification.visible,
                        [styles.green]: !isNotification.error && isNotification.visible,
                        [styles.red]: isNotification.error,
                        [styles.green]: !isNotification.error
                    }
                )}
            >
                {isNotification.error ?
                    "Введіть коректне посилання"
                    :
                    "Успішно, переадресація через секунду.."
                }
            </div>


        </section>
    )
}