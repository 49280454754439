import { Link, useLocation } from 'react-router-dom';
import styles from './Footer.module.scss';
import TelegramIcon from './assets/telegram.svg';
import classnames from 'classnames';

export default function Footer() {
    const location = useLocation();

    return (
        <footer className={classnames(styles["footer"], (location.pathname === '/profile' || location.pathname === '/tariffs') ? styles.gradient : '')}>
            <div className="footer__container">
                <div className={styles["footer__content"]}>
                    <ul className={styles["footer__list"]}>
                        <li className={styles["footer__item"]}>
                            <a href="/privacy-policy" className={styles["footer__link"]}>
                             Політика конфіденційності
                            </a>
                        </li>
                        <li className={styles["footer__item"]}>
                            <a href="/return-policy" className={styles["footer__link"]}>
                             Політика повернення
                            </a>
                        </li>
                        <li className={styles["footer__item"]}>
                            <a href="/rules" className={styles["footer__link"]}>
                             Правила користування
                            </a>
                        </li>
                    </ul>
                    <a href='https://t.me/GramsterTestBot' target='_blank' className={styles["footer__telegram"]}>
                        <img src={TelegramIcon} alt="Telegram Icon" />
                        Telegram
                    </a>
                </div>
            </div>
        </footer>
    )
}