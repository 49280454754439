import styles from '../../SectionInfoRule.module.scss';

export default function SectionReturnPolicy() {
    return (
        <section className="page-info">
            <div className="info-rule__container">
                <div className={styles["info-rule__content"]}>
                    <div className="info-rule-top">
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>Політика повернення коштів</div>
                            <div className={styles["info-rule__content-title"]}>1. Вступ</div>
                            <div className={styles["info-rule__content-description"]}>
                                Ми прагнемо забезпечити високу якість наших послуг. Якщо ви не задоволені своїм підпискою або послугами, ми пропонуємо політику повернення коштів у відповідності до умов, викладених нижче.
                            </div>
                        </div>
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>2. Умови повернення</div>
                            <ul className={styles["info-rule__content-list"]}>
                                <li className={styles["info-rule__content-item"]}>
                                    Термін повернення: Ви можете подати запит на повернення коштів протягом 14 днів з моменту першої оплати за підписку.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Періодичні платежі: Повернення коштів за вже здійснені періодичні платежі (місячні або річні) не передбачено. Ви можете скасувати підписку в будь-який час, щоб уникнути майбутніх платежів.
                                </li>
                            </ul>
                        </div>
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>3. Процедура повернення</div>
                            <ul className={styles["info-rule__content-list"]}>
                                <li className={styles["info-rule__content-item"]}>
                                    1. Зв'яжіться з нами: Напишіть нам на електронну адресу senya@gramster.org щоб повідомити про намір повернути кошти.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    2. Опис проблеми: У своєму запиті надайте інформацію про причину повернення, а також ваші контактні дані і дані облікового запису.
                                </li>
                            </ul>
                        </div>
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>4. Повернення коштів</div>
                            <ul className={styles["info-rule__content-list"]}>
                                <li className={styles["info-rule__content-item"]}>
                                    Обробка запиту: Ми розглянемо ваш запит на повернення коштів протягом 7 робочих днів з моменту отримання вашого запиту.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Сповіщення: Після розгляду вашого запиту ми надішлемо вам повідомлення про схвалення або відхилення повернення коштів.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Повернення: У разі схвалення повернення коштів, гроші будуть зараховані на ваш платіжний засіб, з якого було здійснено оплату, протягом 14 робочих днів.
                                </li>
                            </ul>
                        </div>
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>5. Скасування підписки</div>
                            <ul className={styles["info-rule__content-list"]}>
                                <li className={styles["info-rule__content-item"]}>
                                    Як скасувати підписку: Ви можете скасувати свою підписку в будь-який час через налаштування облікового запису на нашому веб-сайті (на сторінці “поповнити”.
                                </li>
                                <li className={styles["info-rule__content-item"]}>
                                    Припинення платежів: Після скасування підписки, вам більше не будуть нараховуватися майбутні платежі. Підписка залишатиметься активною до кінця вже оплаченої періоду.
                                </li>
                            </ul>
                        </div>
                        <div className={styles["info-rule__content-wrap-text"]}>
                            <div className={styles["info-rule__content-title"]}>6. Контактна інформація</div>
                            <div className={styles["info-rule__content-description"]}>
                                Якщо у вас є запитання або занепокоєння щодо цієї Політики повернення грошей, будь ласка, зв'яжіться з нами за допомогою наступних контактних даних:
                            </div>
                            <ul className={styles["info-rule__content-list"]}>
                                <li className={styles["info-rule__content-item"]}>
                                    Електронна адреса: senya@gramster.org
                                </li>
                            </ul>
                            <div className={styles["info-rule__content-description"]}>
                                Ця Політика повернення грошей набирає чинності з 22.06.2024.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}