import SectionReturnPolicy from "../components/InformationComponents/ReturnPolicy/SectionReturnPolicy/SectionReturnPolicy";
import SectionNavbarInfo from "../components/SectionNavbarInfo/SectionNavbarInfo";

export default function ReturnPolicy() {
    return (
        <main className="page">
            <SectionNavbarInfo />
            <SectionReturnPolicy/>
        </main>
    )
}