import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import Home from './pages/Home';
import Rules from './pages/Rules';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ReturnPolicy from './pages/ReturnPolicy';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Profile from './pages/Profile'; // Импортируйте компонент Profile, если он существует
import './styles/globals.css';
import Tariffs from './pages/Tariffs';

const Main = ({ setLoading }: any) => {
  const location = useLocation();
  const [wrapperClass, setWrapperClass] = useState('wrapper');

  useEffect(() => {
    (window as any).Telegram.WebApp.expand();
    // Имитация загрузки данных
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (location.pathname === '/profile' || location.pathname === '/tariffs') {
      setWrapperClass('wrapper white');
    } else {
      setWrapperClass('wrapper');
    }
  }, [location.pathname]);

  return (
    <div className={wrapperClass}>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/return-policy" element={<ReturnPolicy />} />
        <Route path="/profile" element={<Profile />} /> 
        <Route path="/tariffs" element={<Tariffs />} /> 
      </Routes>
      <Footer />
    </div>
  );
};

export default Main;
