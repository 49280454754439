import classnames from 'classnames'
import styles from './SectionProfileAcc.module.scss'
import Ava1 from './assets/01.jpeg'
import Ava2 from './assets/02.jpeg'
import AddIcon from './assets/add.png';
export default function SectionProfileAcc() {
    return (
        <section className={styles["page__profiles"]}>
            <div className="proflies__container">
                <div className={styles.profiles}>
                    <div className={styles['profiles__title']}>2 профілі</div>
                    <div className={styles["profiles__wrap"]}>
                        <button className={classnames(styles["profiles__profile"], styles.active)}>
                            <img src={Ava1} alt="" className={styles["profiles__profile__pic"]} />
                        </button>
                        <button className={styles["profiles__profile"]}>
                            <img src={Ava2} alt="" className={styles["profiles__profile__pic"]} />
                        </button>
                        <button className={styles["profiles__add-btn"]}>
                            <img src={AddIcon} alt="" className={styles["profiles__add-btn_ic"]} />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}